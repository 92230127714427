import { GatsbyBrowser } from 'gatsby'

import '@/icons/bwi/all-bwi.css'

import '@fontsource/dm-sans/latin-400-italic.css'
import '@fontsource/dm-sans/latin-400.css'
import '@fontsource/dm-sans/latin-500.css'
import '@fontsource/dm-sans/latin-700.css'

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  if (location?.pathname === prevLocation?.pathname && !location?.hash) {
    document.body.scrollIntoView({ behavior: 'smooth' })
  }
  if (location?.hash) {
    const target = document.getElementById(location.hash.substring(1))
    if (target && getComputedStyle(target)?.display === 'none') {
      target.style.display = 'block'
      target.scrollIntoView({ behavior: 'smooth' })
    }
  }
  if (typeof window !== 'undefined' && window._hsq && location?.pathname !== prevLocation?.pathname) {
    window._hsq.push(['setPath', `${location?.pathname}${location?.hash}${location?.search}`])
    window._hsq.push(['trackPageView'])
  }
  if (typeof window !== 'undefined' && window.HubSpotConversations) {
    window.HubSpotConversations.widget.refresh()
  }
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ prevRouterProps, routerProps }) => {
  const disableScrollUpdate = routerProps?.location?.state?.disableScrollUpdate
  const samePageRoute =
    prevRouterProps?.location?.pathname === routerProps?.location?.pathname && !routerProps?.location?.hash
  return !disableScrollUpdate && !samePageRoute
}
